type TContracts = {
  [networkId: string | number]: {
    factory: string;
  }
}

const contracts: TContracts = {
  4: {
    factory: '0x926923238FE6f4866E7FB29a05538e7C4C118a53'
  }, // rinkeby
  1: {
    factory: '0x926923238FE6f4866E7FB29a05538e7C4C118a53'
  }, // mainnet
  5: {
    factory: '0xbb78683fD99F82C3Bc236523C2Ae785442944afD'
  }, // goerli
  137: {
    factory: '0x18165C54C28cF298059d55CA535529a4958852af'
  }, // polygon
  1313161554: {
    factory: '0x926923238FE6f4866E7FB29a05538e7C4C118a53'
  }, // aurora
  80001: {
    factory: '0x926923238FE6f4866E7FB29a05538e7C4C118a53'
  }, // mumbai
  100: {
    factory: '0x926923238FE6f4866E7FB29a05538e7C4C118a53'
  } // xdai
}

export default contracts