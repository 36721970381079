import { FC } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { defineExplorerURL, defineAccountsDeeplink, defineLedgerChain } from 'helpers'
import { 
  Title,
  ButtonsContainer,
  ScreenButton,
  TokenImageSmall,
  TokenImageContainer,
  DoneIcon,
  Subtitle,
  DownloadApplicationStyled
} from './styled-components'

const mapStateToProps = ({
  drop: {
    hash,
    chainId,
    redirectToOnboarding
  },
  user: {
    isLedgerLive,
    address
  },
  token: {
    image,
    name
  }
}: RootState) => ({
  image,
  address,
  name,
  chainId,
  hash,
  isLedgerLive,
  redirectToOnboarding
})

type ReduxType = ReturnType<typeof mapStateToProps>

const ClaimingFinished: FC<ReduxType> = ({
  image,
  name,
  chainId,
  hash,
  isLedgerLive,
  address,
  redirectToOnboarding
}) => {
  let chain = defineLedgerChain({ chainId: String(chainId) })
  return <>
    {image && <TokenImageContainer>
      <DoneIcon />
      <TokenImageSmall
        src={image}
        alt={name}
      />
    </TokenImageContainer>}
    <Title>NFT successfully claimed</Title>
    <Subtitle>Your NFT will appear in your account in a few minutes</Subtitle>
    {!isLedgerLive && <DownloadApplicationStyled />}
    <ButtonsContainer>
      {!redirectToOnboarding && isLedgerLive && <ScreenButton
        title='Go to my accounts'
        href={defineAccountsDeeplink({
          currency: chain,
          address
        })}
      />}
      {!redirectToOnboarding && chainId && hash && <ScreenButton
        title='View in explorer'
        appearance='inverted'
        target='_blank'
        href={`${defineExplorerURL(chainId)}/tx/${hash}`}
      />}
      {redirectToOnboarding && <ScreenButton
        title='Back to setup'
        href='ledgerlive://post-onboarding/nft-claimed?completed=true'
      />}
    </ButtonsContainer>
  </>
}

export default connect(mapStateToProps)(ClaimingFinished)