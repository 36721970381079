import { Dispatch } from 'redux';
import * as actions from '../actions';
import { UserActions } from '../types';
import LinkdropSDK from '@linkdrop/sdk'
import contracts from 'configs/contracts'
import {
  defineServerUrl,
  getHashVariables,
  defineLedgerChain,
  defineSystem
} from 'helpers'
import { ethers } from 'ethers'
import LedgerLiveApi, { WindowMessageTransport } from "@ledgerhq/live-app-sdk";
import checkIfClaimed from '../../drop/async-actions/check-if-claimed'
import { getLastTxHash } from 'data/api'
import * as actionsDrop from '../../drop/actions'
import { DropActions } from '../../drop/types'

import {
  defineNetworkName,
  defineJSONRpcUrl,
} from 'helpers'
import { TAccount } from 'types';
const { REACT_APP_INFURA_ID } = process.env

class StaticJsonRpcProvider extends ethers.providers.JsonRpcProvider {
  async getNetwork(): Promise<any> {
      if (this._network) { return Promise.resolve(this._network); }
      return super.getNetwork();
  }
}

const connectToLL = (llapi: any) => new Promise(async (resolve, reject) => {
  const system = defineSystem()
  const rejectTimeout = window.setTimeout(() => {
    reject('Ledger Live not detected')
  }, system === 'android' ? 1000 : 300)
  const accounts = await llapi.listAccounts()
  if (accounts) {
    clearTimeout(rejectTimeout)
    resolve(accounts)
  }
})

const initialize = () => {
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<DropActions>
  ) => {
    let hashParamsVariables
    const urlVariables = getHashVariables(window.location.href)

    if (urlVariables.linkParams) {
      // redirected via deeplink
      let hashParams = atob(decodeURIComponent(urlVariables.linkParams)).replace('#/', '')
      hashParamsVariables = getHashVariables(hashParams) 
    } else {
      // redirected via regular deeplink
      hashParamsVariables = getHashVariables()
    }

    const chainId = hashParamsVariables.chainId
    const linkdropMasterAddress = hashParamsVariables.linkdropMasterAddress
    const campaignId = hashParamsVariables.campaignId
    const linkKey = hashParamsVariables.linkKey

    dispatch(actions.setInitialized(false))

    if (!REACT_APP_INFURA_ID) {
      return alert('REACT_APP_INFURA_ID is not provided in .env file')
    }

    const jsonRpcUrl = defineJSONRpcUrl({ chainId: Number(chainId), infuraPk: REACT_APP_INFURA_ID })
    const networkName = defineNetworkName(Number(chainId))
    const contract = contracts[chainId]
    const provider = new StaticJsonRpcProvider(jsonRpcUrl)
    dispatch(actions.setProvider(provider))

    const linkWallet = await new ethers.Wallet(linkKey, provider)
    const linkId = linkWallet.address
    const claimed = await checkIfClaimed(
      provider,
      Number(chainId),
      linkId,
      linkdropMasterAddress,
      campaignId
    )

    if (claimed) {
      try {
        const latestTxHash = await getLastTxHash(Number(chainId), linkdropMasterAddress, linkId)
        if (latestTxHash.data.txHash) {
          dispatch(actionsDrop.setHash(latestTxHash.data.txHash))
        }
      } catch (err) {
        console.log({ err })
      }
      dispatch(actionsDrop.setIsClaimed(claimed))
    }
    
    try {
      const llapi = new LedgerLiveApi(new WindowMessageTransport())    
      llapi.connect()
      await connectToLL(llapi)
      // await connectToLL(llapi)
      dispatch(actions.setIsLedgerLive(true))
      if (!claimed) {
        let chain = defineLedgerChain({ chainId: String(chainId) })
        try {
          const accounts = await llapi.listAccounts()
          const appropriateAccount = accounts.filter(account => account.currency === chain)
          if (appropriateAccount.length === 1) {
            dispatch(actions.setAccount(appropriateAccount[0]))
            dispatch(actions.setAddress(appropriateAccount[0].address || ''))
          } else {
            const requestAccount: TAccount = await llapi.requestAccount({
              currencies: [ 'polygon' ]
            })
            dispatch(actions.setAccount(requestAccount))
            dispatch(actions.setAddress(requestAccount.address || ''))
          }
          
        } catch (err) {
          dispatch(actions.setIsLedgerLive(true))
          console.log('error with method requestAccount', err)
        }
      }
    } catch (err) {
      dispatch(actions.setIsLedgerLive(false))
      console.log('failed connection to Ledger Live', err)
    }

    if (!contract) {
      return dispatch(actions.setInitialized(true))
    }

    const apiHost = defineServerUrl(Number(chainId))
    console.log({
      apiHost
    })
    const sdk = new LinkdropSDK({
      factoryAddress: contract.factory,
      chain: networkName,
      linkdropMasterAddress,
      jsonRpcUrl,
      apiHost
    })

    dispatch(actions.setSDK(sdk)) 
    
    dispatch(actions.setInitialized(true))
  }
}

export default initialize