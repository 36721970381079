import { Dispatch } from 'redux';
import * as actions from '../actions';
import { UserActions } from '../types';
import { defineLedgerChain } from 'helpers'
import LedgerLiveApi, { WindowMessageTransport } from "@ledgerhq/live-app-sdk";
import { DropActions } from '../../drop/types'
import { RootState } from 'data/store'

import { TAccount } from 'types';

const selectAccount = () => {
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<DropActions>,
    getState: () => RootState
  ) => {
    let {
      drop: {
        chainId
      }
    } = getState()
    try {
      const llapi = new LedgerLiveApi(new WindowMessageTransport())    
      llapi.connect()
      let chain = defineLedgerChain({ chainId: String(chainId) })
      try {
        const requestAccount: TAccount = await llapi.requestAccount({
            // currencies: [ chain ]
            currencies: [ 'polygon' ]
        })
        dispatch(actions.setAccount(requestAccount))
        dispatch(actions.setAddress(requestAccount.address || ''))
      } catch (err) {
        console.log('error with method requestAccount', err)
      }
    } catch (err) {
      console.log('failed connection to Ledger Live', err)
    }
  }
}

export default selectAccount