import { FC, useEffect } from 'react'
import { ScreenSubtitle, ScreenTitle, Container, ButtonComponent } from './styled-components'
import { Loader } from 'components/common'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { defineExplorerURL } from 'helpers'
import * as dropAsyncActions from 'data/store/reducers/drop/async-actions'

const mapStateToProps = ({
  drop: { hash, chainId }
}: RootState) => ({
  hash,
  chainId
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    checkTransactionStatus: () => dispatch(
      dropAsyncActions.checkTransactionStatus()
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ClaimingProcess: FC<ReduxType> = ({ hash, chainId, checkTransactionStatus }) => {
  useEffect(() => {
    if (!hash) { return }
    checkTransactionStatus()
  }, [])

  const explorerUrl = chainId ? <ButtonComponent
    href={`${defineExplorerURL(chainId)}/tx/${hash}`}
    title='View in explorer'
    target='_blank'
    appearance='inverted'
  /> : null

  return <Container>
    <Loader />
    <ScreenTitle>Waiting for the transaction to complete...</ScreenTitle>
    <ScreenSubtitle>This operation can take some minutes.</ScreenSubtitle>
    {explorerUrl}
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ClaimingProcess)
