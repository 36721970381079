import { FC } from 'react'
import {
  Container,
  AccountLogo,
  AccountName,
  CurrentAccount,
  CurrentAccountChange
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { defineLedgerChain, shortenString } from 'helpers'
import { connect } from 'react-redux'
import * as userActions from 'data/store/reducers/user/actions'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'

import { UserActions } from 'data/store/reducers/user/types'
import { Dispatch } from 'redux'
import Icons from 'icons'

const mapStateToProps = ({
  drop: {
    step, chainId
  },
  user: {
    address,
    account,
    isLedgerLive
  }
}: RootState) => ({
  step,
  address,
  account,
  chainId,
  isLedgerLive
})

const mapDispatcherToProps = (dispatch: Dispatch<UserActions> & IAppDispatch) => {
  return {
    setAddress: (address: string) => dispatch(
      userActions.setAddress(address)
    ),
    selectAddress: () => dispatch(
      userAsyncActions.selectAccount()
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const SelectComponent: FC<ReduxType> = ({
  account,
  chainId,
  selectAddress,
  isLedgerLive
}) => {
  const defineLogo = (chainId: string) => {
    switch (chainId) {
      case '137':
        return <Icons.PolygonIcon />
      default:
        return <Icons.EthereumIcon />
    }
  }

  if (!isLedgerLive) {
    return null
  }

  if (!account) {
    return <Container>
      <CurrentAccount>
        <AccountLogo chain={defineLedgerChain({ chainId: String(chainId) })}>
          {defineLogo(String(chainId))}
        </AccountLogo>
        <AccountName>
          No Account selected
        </AccountName>
        <CurrentAccountChange onClick={selectAddress}>
          Select Account
        </CurrentAccountChange>
      </CurrentAccount>
    </Container>
  }

  return <Container>
    <CurrentAccount>
      <AccountLogo chain={defineLedgerChain({ chainId: String(chainId) })}>
        {defineLogo(String(chainId))}
      </AccountLogo>
      <AccountName>{account.name} ({shortenString(account.address)})</AccountName>
      <CurrentAccountChange onClick={selectAddress}>
        Select Account
      </CurrentAccountChange>
    </CurrentAccount>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(SelectComponent)